@font-face {
  font-family: "JetBrains Mono";
  src: local("JetBrains Mono"),
    url(/static/media/JetBrainsMono-Regular.4c8c378e.woff2) format("woff2"),
    url(/static/media/JetBrainsMono-Regular.ebe2f0f7.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "CoM";
  src: local("CoM"), url(/static/media/CoM.700fea12.woff2) format("woff2"),
    url(/static/media/CoM.0f4e2f0f.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: local("GothamPro-Medium"), url(/static/media/GothamPro-Medium.738777ea.woff2) format("woff2"),
    url(/static/media/GothamPro-Medium.3c379e63.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: local("GothamPro"), url(/static/media/GothamPro.97a23059.woff2) format("woff2"),
    url(/static/media/GothamPro.9e3de475.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: local("GothamPro-Black"), url(/static/media/GothamPro-Black.7ed52d13.woff2) format("woff2"),
    url(/static/media/GothamPro-Black.b8ef8e03.woff) format("woff");
  font-weight: 900;
  font-style: normal;
}

