@font-face {
  font-family: "CoM";
  src: local("CoM"), url("CoM.woff2") format("woff2"),
    url("CoM.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: local("GothamPro-Medium"), url("GothamPro-Medium.woff2") format("woff2"),
    url("GothamPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: local("GothamPro"), url("GothamPro.woff2") format("woff2"),
    url("GothamPro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: local("GothamPro-Black"), url("GothamPro-Black.woff2") format("woff2"),
    url("GothamPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
